<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-navigation-drawer
        v-model="computedDrawer"
        app
        class="overflow-y-hidden"
        clipped
        expand-on-hover
      >
        <v-list :class="{ 'overflow-y-auto': hover }" style="height: 100%">
          <template v-for="item in expandedNavbarList">
            <v-list-group
              v-if="item.children"
              :key="item.text"
              v-model="item.model"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
            >
              <template #prependIcon>
                <v-icon
                  size="20px"
                  v-text="item.model ? item.icon : item['icon-alt']"
                ></v-icon>
              </template>

              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title> {{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-for="child in item.children">
                <v-subheader
                  v-if="child.heading"
                  :key="`heading-${child.heading}`"
                  class="text-subtitle-2 group-text--heading ml-10 pl-0"
                  inset
                  v-text="child.heading"
                />

                <v-list-group
                  v-else-if="child.children"
                  :key="child.text"
                  v-model="child.model"
                  class="sub-group__custom"
                  prepend-icon=""
                  sub-group
                  v-bind="$attrs"
                >
                  <template #appendIcon>
                    <v-icon>mdi-menu-down</v-icon>
                  </template>

                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="pl-2">
                        {{ child.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="(subChild, idx) in child.children"
                    :key="idx"
                    :to="subChild.link"
                    class="pl-10"
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title class="pl-6">
                        {{ subChild.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-list-item
                  v-else
                  :key="child.text"
                  :to="child.link"
                  class="pl-10"
                  link
                >
                  <v-list-item-action v-if="child.icon">
                    <v-icon size="20px">{{ child.icon }}</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ child.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-item
              v-else
              :key="item.text"
              :to="item.link"
              color="primary"
              link
            >
              <v-list-item-action>
                <v-icon size="20px">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
    </v-hover>
  </div>
</template>

<script>
import { cloneDeep } from "@vt7/utils";
import {
  LIST_ACCOUNTANT_NAVBAR,
  LIST_CONTENT_NAVBAR,
  LIST_FREELANCER_WRITER_NAVBAR,
  LIST_FULL_NAVBAR,
  LIST_INVENTORY_MANAGER_NAVBAR,
  LIST_MARKETING_NAVBAR,
  LIST_PRODUCT_ASSISTANT_NAVBAR,
  LIST_ROLES,
  LIST_SALE_NAVBAR,
  LIST_STORE_MANAGER_NAVBAR,
  LIST_SEO_CTV_NAVBAR,
  LIST_SEO_NAVBAR
} from "../../constant";

export default {
  props: {
    drawer: Boolean
  },

  computed: {
    computedDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$emit("updateDrawer", val);
      }
    },
    navbarList() {
      if (this.user) {
        const role = this.user.role.toLowerCase();

        if (role === "admin") {
          return LIST_FULL_NAVBAR;
        }

        if (role === LIST_ROLES.accountant.name) {
          return LIST_ACCOUNTANT_NAVBAR;
        }

        if (role === LIST_ROLES.sale.name) {
          return LIST_SALE_NAVBAR;
        }

        if (role === LIST_ROLES.storeManager.name) {
          return LIST_STORE_MANAGER_NAVBAR;
        }

        if (role === LIST_ROLES.content.name) {
          return LIST_CONTENT_NAVBAR;
        }

        if (role === LIST_ROLES.marketing.name) {
          return LIST_MARKETING_NAVBAR;
        }

        if (role === LIST_ROLES.freelancerWriter.name) {
          return LIST_FREELANCER_WRITER_NAVBAR;
        }

        if (role === LIST_ROLES.productAssistant.name) {
          return LIST_PRODUCT_ASSISTANT_NAVBAR;
        }

        if (role === LIST_ROLES.inventoryManager.name) {
          return LIST_INVENTORY_MANAGER_NAVBAR;
        }

        if (role === LIST_ROLES.seo.name) {
          return LIST_SEO_NAVBAR;
        }

        if (role === LIST_ROLES.ctvSeo.name) {
          return LIST_SEO_CTV_NAVBAR;
        }
      }

      return LIST_SALE_NAVBAR;
    },
    expandedNavbarList() {
      const tree = cloneDeep(this.navbarList);
      const key = this.$route.name;

      const traverse = node => {
        if (node?.link?.name === key) {
          node.model = true;
          return true;
        }

        if (node.children) {
          for (const child of node.children) {
            if (traverse(child)) {
              node.model = true;
              return true;
            }
          }
        }

        return false;
      };

      for (const node of tree) {
        traverse(node);
      }

      return tree;
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  }
};
</script>

<style lang="scss" scoped>
.group-text--heading {
  color: #9d9d9d;
}

::v-deep .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
</style>
